import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.section`
  padding-left: 16px;
  padding-right: 16px;
  z-index: 2;

  .inner {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Text = styled.p`
  font-size: 17px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #0a1c61;
  margin: 0;
  margin-bottom: 16px;
  max-width: 800px;
  width: 100%;

  @media ${media.tablet} {
    text-align: left;
    max-width: 608px;
  }

  @media ${media.mobile} {
    margin-bottom: 8px;
  }
`
