import styled, { css } from "styled-components"

import { Title, media } from "src/uikit"

import bgSrc from "./assets/bg.png"

const orderStyle = ({ inverse }) =>
  inverse
    ? css`
        :nth-child(2n) {
          background-image: url(${bgSrc});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center top;
          padding-top: 100px;
          padding-bottom: 120px;
        }

        :nth-child(2n + 1) {
          .text-container {
            order: 1;
            margin-left: 0;
            margin-right: 45px;
          }

          .image-container {
            order: 2;
            margin-right: 0;
            margin-left: 45px;
          }
        }

        .text-container {
          order: 2;
          margin-left: 45px;
          margin-right: 0;
        }

        .image-container {
          order: 1;
          margin-left: 0;
          margin-right: 45px;
        }

        @media (max-width: 1100px) {
          margin-top: -60px;

          :first-child {
            padding-top: 0;
            margin-top: 64px;
          }

          :nth-child(2n) {
            padding-top: 160px;
          }
        }

        @media ${media.mobile} {
          :nth-child(2n) {
            padding-top: 120px;
          }

          :first-child {
            margin-top: 64px;
          }
        }
      `
    : css`
        padding-bottom: 120px;

        :nth-child(2n + 1) {
          .text-container {
            order: 2;
            margin-left: 45px;
            margin-right: 0;
          }

          .image-container {
            order: 1;
            margin-right: 45px;
            margin-left: 0;
          }
        }

        .text-container {
          order: 1;
          margin-left: 0;
          margin-right: 45px;
        }

        .image-container {
          order: 2;
          margin-left: 45px;
          margin-right: 0;
        }

        @media (max-width: 1100px) {
          padding-bottom: 80px;

          :first-child {
            padding-top: 80px;
          }
        }

        @media ${media.mobile} {
          padding-bottom: 64px;

          :first-child {
            padding-top: 64px;
          }
        }
      `

export const Container = styled.section`
  background-color: #fff;

  :first-child {
    padding-top: ${props => (props.inverse ? 100 : 140)}px;
  }

  .inner {
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-container {
    width: 480px;

    margin-left: 45px;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    margin-right: 45px;
  }

  ${orderStyle}

  img {
    max-width: 100%;
  }

  ${Title} {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.4px;
    color: #0a1c61;
    font-weight: bold;
  }

  .player-container {
    width: 295px;
  }

  @media (max-width: 1100px) {
    .inner {
      flex-direction: column;
    }

    .text-container {
      width: 608px;
      order: 1 !important;
      margin: 0;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 40px;
    }

    .image-container {
      max-width: 381px;
      order: 2 !important;
      margin: 0;
    }
  }

  @media ${media.mobile} {
    padding-left: 16px;
    padding-right: 16px;

    ${Title} {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.2px;
    }

    .text-container,
    .image-container {
      margin: 0 !important;
    }

    .text-container {
      width: 100%;
      margin-bottom: 40px !important;

      button,
      a {
        width: 100%;
      }
    }

    .image-container {
      max-width: 100%;
    }
  }
`

export const AttachmentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${props => (props.inverse ? 56 : 0)}px;

  padding: 0 16px;
  z-index: 2;

  @media ${media.mobile} {
    margin-bottom: ${props => (props.inverse ? 20 : 0)}px;
  }
`
