import React from "react"

import { Title, Indent } from "src/uikit"

import Form from "../Form"
import * as S from "./styled"

const SecondForm = ({ heading, onSuccess, downloadAssetModalId }) => {
  return (
    <S.Container>
      <div className="inner">
        <Title>{heading}</Title>
        <Indent lg={48} md={32} sm={32} />
        <div className="form-container">
          <Form
            transparent
            downloadAssetModalId={downloadAssetModalId}
            btnColor="purple"
            onSuccess={onSuccess}
          />
        </div>
      </div>
      <Indent lg={64} />
    </S.Container>
  )
}

export default SecondForm
