import React from "react"
import { graphql, navigate } from "gatsby"
import { useLocale } from "src/common/hooks"

import { getNodeByLocale } from "src/common/getNodesByLocale"
import { useHeaderColor } from "src/common/hooks"
import Layout from "src/layout"

import Banner from "./components/Banner"
import PrefeaturesText from "./components/PrefeaturesText"
import Features from "../components/Features"
import SecondForm from "./components/SecondForm"

const ExtendedAdPage = ({ data, location }) => {
  const locale = useLocale()
  const locales = getNodeByLocale(
    data.allContentfulSolutionsExtendedAdPage.nodes,
    locale
  )

  const handleSuccessSubmit = () =>
    navigate(`/${locales.downloadAssetModal?.modalId}-ty`)

  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: true,
    elem: { selector: "#content", inverse: false },
  })

  const headerColor = {
    primaryColor:
      "linear-gradient(255.03deg, #7785FF -0.91%, #6472EF 102.89%) #fff",
    secondaryColor: "#fff",
  }

  return (
    <Layout
      location={location}
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
      title={locales.heading}
      descritpion={locales.seoDescription}
    >
      <Banner
        downloadAssetModalId={locales.downloadAssetModal.modalId}
        json={locales.headline.json}
        onSuccess={handleSuccessSubmit}
      />
      {locales.prefeaturesText && (
        <PrefeaturesText json={locales.prefeaturesText.json} />
      )}
      <Features json={locales.features.json} />
      <SecondForm
        downloadAssetModalId={locales.downloadAssetModal.modalId}
        heading={locales.headline2}
        onSuccess={handleSuccessSubmit}
      />
    </Layout>
  )
}

export default ExtendedAdPage

export const query = graphql`
  query($slug: String!) {
    allContentfulSolutionsExtendedAdPage(filter: { pageId: { eq: $slug } }) {
      nodes {
        pageId
        node_locale
        heading
        prefeaturesText {
          json
        }
        features {
          json
        }
        headline2
        headline {
          __typename
          json
        }
        downloadAssetModal {
          modalId
        }
      }
    }
  }
`
