import React, { useRef, useEffect, useState } from "react"

import { PlayIcon, PauseIcon } from "src/media"

import useAudio from "./useAudio"
import { formatTime } from "./utils"
import * as S from "./styled"

const AudioPlayer = ({ src, contentType, name, duration: defaultDuration }) => {
  const audioEl = useRef(null)
  const progress = React.createRef()
  const [offset, setOffset] = useState(0)
  const { curTime, duration, isPlaying, setIsPlaying } = useAudio(audioEl)

  useEffect(() => {
    const { current } = progress
    const seekbar = current.getTotalLength()
    const offset = (
      seekbar -
      (curTime / (duration || defaultDuration)) * seekbar
    ).toFixed(2)
    setOffset(offset)
  }, [curTime])

  const togglePlay = async () => {
    if (!audioEl.current) {
      return
    }

    setIsPlaying(!isPlaying)

    if (isPlaying) {
      audioEl.current.pause()
      return
    }

    try {
      await audioEl.current.play()
    } catch (err) {
      // console.log('err:', err)
    }
  }

  return (
    <S.Player>
      <S.PlayerSlider>
        <S.PlayerAudio controls ref={audioEl}>
          <source src={src} type={contentType} />
        </S.PlayerAudio>

        <S.PlayerControls>
          <S.UnderlayCircle isPlaying={isPlaying} />
          <S.Circle ref={progress} offset={offset} />

          <S.PlayerButton onClick={togglePlay}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </S.PlayerButton>
        </S.PlayerControls>
      </S.PlayerSlider>

      <S.AudionInfo>
        <S.Name>{name}</S.Name>

        <S.Time>
          {isPlaying && (
            <>
              <S.CurrentTime>{formatTime(curTime)}</S.CurrentTime>
              <S.Divider>/</S.Divider>
            </>
          )}
          <S.TotalTime>{formatTime(duration || defaultDuration)}</S.TotalTime>
        </S.Time>
      </S.AudionInfo>
    </S.Player>
  )
}

export default AudioPlayer
