import React from "react"
import { useLocale } from "src/common/hooks"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import get from "lodash/get"

import { Title, Indent } from "src/uikit"
import Button from "src/newUikit/Button"
import { getModalClickHandler } from "src/components/ModalsContainer/events"

import Description from "./components/Description"
import AudioPlayer from "./components/AudioPlayer"
import * as S from "./styled"

const getOptions = (locale, inverseOrder) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: () => "",
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      try {
        const { fields } = node.data.target
        const title = get(fields, `heading.${locale}`, null)
        const description = get(fields, `description.${locale}`, null)
        const image = get(fields, `image.en-US.fields.file.${locale}.url`, null)
        const imageAlt = get(
          fields,
          `image.en-US.fields.description.${locale}`,
          null
        )
        const audioSample = get(
          fields,
          `audioSample.en-US.fields.file.${locale}`,
          null
        )
        const audioTitle = get(fields, `audioTitle.${locale}`, null)
        const audioDuration = get(fields, `audioDuration.${locale}`, null)
        const ctaName = get(fields, `ctaButtonName.${locale}`, null)
        const ctaUrl = get(fields, `ctaButtonUrl.${locale}`, null)
        const ctaModalType = get(fields, `ctaModalType.${locale}`, null)
        const ctaModalTitle = get(fields, `ctaModalTitle.${locale}`, null)

        return (
          <S.Container inverse={inverseOrder ? 1 : 0}>
            <div className="inner">
              <div className="image-container">
                <img className="lazyload" data-src={image} alt={imageAlt} />
                {audioSample && (
                  <>
                    <Indent lg={40} md={40} sm={40} />
                    <div className="player-container">
                      <AudioPlayer
                        src={audioSample.url}
                        contentType={audioSample.contentType}
                        name={audioTitle}
                        duration={audioDuration}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="text-container">
                <Title>{title}</Title>
                <Indent lg={16} md={16} sm={16} />
                <Description json={description} />
                {ctaName && ctaUrl && (
                  <>
                    <Indent lg={24} md={24} sm={24} />
                    <Button inverse color="purple" size="small" url={ctaUrl}>
                      {ctaName}
                    </Button>
                  </>
                )}
                {ctaName && ctaModalType && (
                  <>
                    <Indent lg={24} md={24} sm={24} />
                    <Button
                      inverse
                      color="purple"
                      size="small"
                      onClick={getModalClickHandler(ctaModalType, {
                        title: ctaModalTitle,
                      })}
                    >
                      {ctaName}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </S.Container>
        )
      } catch (err) {
        // console.log("err: ", err)

        return <div></div>
      }
    },
  },
})

const Features = ({ inverseOrder = false, json, ctaBtnName, onCtaClick }) => {
  const locale = useLocale()

  return (
    <div id="content">
      {documentToReactComponents(json, getOptions(locale, inverseOrder))}
      <S.AttachmentBox inverse={inverseOrder ? 1 : 0}>
        {!!ctaBtnName && (
          <Button inverse size="large" onClick={onCtaClick}>
            {ctaBtnName}
          </Button>
        )}
      </S.AttachmentBox>
    </div>
  )
}

export default Features
