import styled from "styled-components"

import { Title, media } from "src/uikit"

import bgSrc from "./assets/bg.png"

export const Container = styled.section`
  padding-left: 16px;
  padding-right: 16px;
  background-image: url(${bgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  margin-top: -150px;

  button {
    min-width: 288px;
  }

  .inner {
    padding-top: 150px;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${Title} {
    text-align: center;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
  }

  .form-container {
    max-width: 512px;
  }

  @media ${media.mobile} {
    .inner {
      padding-top: 110px;
    }

    ${Title} {
      font-size: 26px;
      line-height: 32px;
    }

    button {
      width: 100%;
    }
  }
`
