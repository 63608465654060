import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { Indent } from "src/uikit"

import * as S from "./styled"

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => <S.Text>{children}</S.Text>,
  },
}

const PrefeaturesText = ({ json }) => (
  <S.Container>
    <div className="inner">
      <Indent lg={80} md={80} sm={64} />
      {documentToReactComponents(json, options)}
      <Indent lg={-60} md={0} sm={0} />
    </div>
  </S.Container>
)

export default PrefeaturesText
